import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { widgetId } from './utils/metaInfo';

export const editorReady: EditorReadyFn = () => {};

export const getAppManifest: GetAppManifestFn = (
  { appManifestBuilder },
  editorSDK,
  context,
  { translations },
) =>
  appManifestBuilder
    .configureWidget(widgetId, (widgetBuilder) => {
      widgetBuilder
        .set({
          displayName: translations.t('settings.title'),
          nickname: 'banner',
        })
        .gfpp()
        .set('help', { id: 'c99b435b-8acc-4ace-9d17-78fee85466b6' });
    })
    .build();
